import React from 'react'
import clsx from 'clsx'
import * as styles from './ProjectText.module.scss'

const headerTextMapping = {
  FallSeason: 'Fall',
  WinterSeason: 'Winter',
  SpringSeason: 'Spring'
}

const copyMapping = {
  FallSeason: [
    '🚨 new project alert! 🚨',
    '💓 feature / heartbeat',
    '🧠 feature / brain-mapping',
    '🤢 fix / morning-bug',
    '🖐️ feature / build-fingers',
  ],
  WinterSeason:[
    '🤸 feature / initiate-wiggling',
    '👀 feature / grow-eyeballs',
    '👂 feature / develop-hearing',
    '👍 feature / suck-thumb',
    '🤪 feature / hiccups'
  ],
  SpringSeason: [
    '🦵 feature / maximize-kick-function',
    '🤔 wip / name-picking',
    '🏠 chore / nesting',
    '💤 fix / sleep-regression',
    '🔍 final review 🔍',
  ]
}

const ProjectText = ({
  season
}) => {
	return season ? (
    <div className={clsx(
      styles.ProjectText,
      styles[season]
    )}>
      <div className={styles.headerText}>
        {headerTextMapping[season]}
      </div>
      <div className={styles.copyText}>
        { 
          season ? 
            copyMapping[season]?.map((copy, idx) => (
              <div className={clsx(styles[`copy${idx + 1}`], styles.copy)}>
                {copy}
              </div> 
            
            )) : null
        }
      </div>
    </div>
	) : null
}

export default ProjectText