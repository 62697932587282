// extracted by mini-css-extract-plugin
export var FallSeason = "ProjectText-module--FallSeason--2u5jo";
export var ProjectText = "ProjectText-module--ProjectText--pXFbL";
export var SpringSeason = "ProjectText-module--SpringSeason--rG5VT";
export var WinterSeason = "ProjectText-module--WinterSeason--d1vIm";
export var copy = "ProjectText-module--copy--5vIjb";
export var copy1 = "ProjectText-module--copy1--nkkI8";
export var copy2 = "ProjectText-module--copy2--S2wu3";
export var copy3 = "ProjectText-module--copy3--9WjiM";
export var copy4 = "ProjectText-module--copy4--C7dcr";
export var copy5 = "ProjectText-module--copy5--ic4SY";
export var fallFadeInOut = "ProjectText-module--fallFadeInOut--hfedD";
export var headerText = "ProjectText-module--headerText--S7nnq";
export var springFadeInOut = "ProjectText-module--springFadeInOut--aYJwZ";
export var winterFadeInOut = "ProjectText-module--winterFadeInOut--TLeEx";